import React from 'react';

const Overlay = () => {
return(
    <>
        <div className="overlay"></div>
    </>
)
}

export default Overlay;